import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class AppConfigService {

    /**
     *
     */
    private appConfig;

    /**
     *
     * @param {HttpClient} http
     * @param {Object} platformId
     */
    constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    }


    /**
     * Makes Service Call And Saves Configuration In Local Variable
     * @returns {Promise<void>}
     */
    loadAppConfig() {
        

        const appConfigURL = isPlatformBrowser(this.platformId)
        ? '/assets/config.json'
        : 'http://localhost:4000/assets/config.json'

        // alert(isPlatformBrowser(this.platformId));
        // alert(appConfigURL);

        return this.http.get(appConfigURL)
            .toPromise()
            .then(data => {
                this.appConfig = data;
            });
    }


    /**
     * Responsible To Get Configuration From Variable After It's laoded
     * @returns {any}
     */
    getConfig(): AppConfiguration {
        return this.appConfig;
    }

}

/**
 *
 */
export interface AppConfiguration {
    GENERAL: {
        AVAILABLE_LANGUAGES: Array<string>,
        DEFAULT_LANGUAGE: string;
        DEFAULT_CURRENCY: string;
        DEFAULT_PAGE: string;
        PROFILE: {
            HISTORY_DEFAULT_DATE_RANGE_DAYS: number,
            HISTORY_PAGE_SIZE: number
        }
    },
    CASINO_API: {
        WEBSITE_API_PATH: string;
        CORE_API_PATH: string;
    },
    GAME_INTEGRATIONS_API: {
        CLIENT_ID: string;
        API_PATH: string;
    },
    PAYMENT_INTEGRATIONS_API: {
        CLIENT_ID: any;
        IFRAME_UI_PATH: string;
    }
}

export const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};