import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Inject, Injectable, NgModule, PLATFORM_ID, TransferState } from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';


//This Sets as first conponent on every request
import {LangComponent} from './shared/components/lang/lang.component';

//Module For Making HTTP Calls
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

//Module Which Helps To De
import { DeviceDetectorService } from 'ngx-device-detector';


//Translation Module
// import {L10nTranslationModule, L10nIntlModule} from 'angular-l10n';

import {AppConfigService, appInitializerFn} from "./app.config.service";
// import {initLocalization, LocalizationConfig} from "./app.translation.service";
// import {l10nConfig} from "./shared/l10n.config";


/**
 * Application's main module
 */
@NgModule({ declarations: [
        AppComponent,
        LangComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'casino-website' }),
        AppRoutingModule], providers: [
        TransferState,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
