import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import { DeviceDetectorService } from 'ngx-device-detector';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
    /**
     * @memberof AppComponent
     */
    public loading = true;

    /**
     * @memberof AppComponent
     */
    public loaded = false;

    /**
     *
     * @param {Router} router
     * @param {Device} device
     * @param document
     * @param {Renderer2} r
     */
    constructor(private router: Router, private deviceService: DeviceDetectorService, @Inject(DOCUMENT) document, r: Renderer2) {
        let bodyClass = deviceService.isMobile() ? 'is-mobile' : 'is-desktop';
        r.addClass(document.body, bodyClass);
    }


    /**
     * @memberof AppComponent
     */
    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.loaded = true;

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }

            // if(window) {
            //     window.scroll(0, 0);
            // }
        });
    }
}
