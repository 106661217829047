import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LangComponent} from "./shared/components/lang/lang.component";


const routes: Routes = [
    {
        path: ':lang',
        component: LangComponent
    },
    {
        path: ':lang/:page',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    },
    {
        path: ':lang/:page/:param',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    },
    {
        path: '**',
        component: LangComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
