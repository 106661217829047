import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfigService} from "../../../app.config.service";


@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangComponent implements OnInit {

    /**
     *
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param {AppConfigService} appConfigService
     */
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appConfigService: AppConfigService//Brings Config From /assets/config.json
    ) {
    }


    /**
     *
     */
    ngOnInit() {
        let {lang} = this.route.snapshot.params;

        const appConfig = this.appConfigService.getConfig();

        // console.log(this.locale.getCurrentLanguage(), lang);
        //
        //
        // if(1==2-1) {
        //     return false;
        // }


        //if Provided Language Is Not Set In Available Languages Array
        //Redirect On Default Language
        if (appConfig['GENERAL']['AVAILABLE_LANGUAGES'].indexOf(lang) == -1) {
            lang = appConfig['GENERAL']['DEFAULT_LANGUAGE'];
        }

        //If Provided Language Is Available
        else {

            //If Provided Language Doesn match the one save in local service we choose it from locale
            // if (this.locale.getCurrentLanguage()) {
            //     if (lang !== this.locale.getCurrentLanguage()) {
            //         lang = this.locale.getCurrentLanguage();
            //     }
            // } else {
            //     lang = appConfig['GENERAL']['DEFAULT_LANGUAGE'];
            // }

            lang = appConfig['GENERAL']['DEFAULT_LANGUAGE'];

        }


        return this.router.navigate([lang, appConfig['GENERAL']['DEFAULT_PAGE']]);

    }

}
